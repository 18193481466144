import React, { createContext, useEffect, useState } from "react";
import { injected } from "src/connectors";
import { useWeb3React } from "@web3-react/core";
import { ACTIVE_NETWORK } from "src/constants";
import {
  getBalanceOf,
  getContract,
  getWeb3ContractObject,
  getWeb3Obj,
  swichNetworkHandler,
} from "src/utils";
import Web3 from "web3";
import { token } from "src/constants";
// import abi from "src/ABI/Abi2.json";
import { TestERC, nftRegister } from "src/constants";
import TestERCABI from "src/ABI/TestERCABI.json";
import NFTRegistryABI from "src/ABI/NFTRegistryABI.json";
import { ethers } from "ethers";
// import { abi } from "src/constants";
import abi from "src/ABI/Abi2.json";
import axios from "axios";
import { toast } from "react-toastify";

export const UserContext = createContext();

const setSession = (userAddress) => {
  if (userAddress) {
    sessionStorage.setItem("userAddress", userAddress);
  } else {
    sessionStorage.removeItem("userAddress");
  }
};

export default function AuthProvider(props) {
  const { activate, account, library, deactivate, chainId } = useWeb3React();
  const [nftPrice, setNftPrice] = useState(0);
  const [hasFinalSaleStarted, setHasFinalSaleStarted] = useState(false);
  // const [reservedClaimed, setReservedClaimed] = useState(1);
  // const [RESERVED_NFT, setRESERVED_NFT] = useState(1);
  const [MAX_NFT_SUPPLY, setMAX_NFT_SUPPLY] = useState(0);
  const [totalSupply, setTotalSupply] = useState(0);
  const [MAX_MINT, setMAX_MINT] = useState(100);
  const [MAX_NFT_WALLET, setMAX_NFT_WALLET] = useState(0);
  const [balanceOfValue, setBalanceOfValue] = useState(0);
  const [publicSaleStartTimestamp, setPublicSaleStartTimestamp] = useState(0);
  const [adminWalletAddress, setAdminWalletAddress] = useState("");
  const [allNftList, setallNftList] = useState([]);
  const [userNFTList, setUserNFTList] = useState([]);
  const [mintPercentage, setMintPercentage] = useState(0);
  const [isLoadingAllNFT, setIsLoadingAllNFT] = useState(false);
  const [dataloader, setDataloader] = React.useState(false);
  const [cryptovalue, setCrptovalue] = useState(0);
  const Webobj = (abi, token) => {
    const web3 = (window.web3 = new Web3(window.ethereum));
    const contract = new web3.eth.Contract(abi, token);
    return contract;
  };
  const [, setData] = React.useState();

  const [walletDetails, setwalletDetails] = React.useState([]);
  console.log("walletDetails12#", walletDetails);
  const [available, setavailable] = React.useState(false);

  const Cryptoowned = async () => {
    const contract = Webobj(abi, token);

    // const contract = getContract(token, abi, library, account);
    const tx = await contract.methods.balanceOf(account).call();
    // console.log(tx);
    setCrptovalue(tx);
  };
  const [walletvalue, setWalletvalue] = useState(0);

  const Walletdata = async () => {
    const contract = Webobj(TestERCABI, TestERC);
    const tx = await contract.methods.balanceOf(account).call();
    const etherValue = ethers.utils.formatEther(tx);
    setWalletvalue(etherValue);
  };
  let data = {
    nftPrice,
    allNftList,
    balanceOfValue,
    adminWalletAddress,
    isLoadingAllNFT,
    walletDetails,
    cryptovalue,
    dataloader,
    // reservedClaimed,
    // RESERVED_NFT,
    MAX_NFT_SUPPLY,
    totalSupply,
    MAX_MINT,
    MAX_NFT_WALLET,
    hasFinalSaleStarted,
    publicSaleStartTimestamp,
    userNFTList,
    mintPercentage,
    getCurrentMintingDetails: () => getCurrentMintingDetails(),
    updateUser: (account) => {
      setSession(account);
    },
    connectWallet: () => {
      activate(injected, undefined, true).catch((error) => {
        if (error) {
          toast.warn(error.message);
          activate(injected);
        }
      });
    },
  };

  const userNFTListHadler = async (balanceOf, cancelTokenSource) => {
    setUserNFTList([]);
    const contract = getContract(token, abi, library, account);

    try {
      for (let i = 0; i < balanceOf; i++) {
        const id = await contract.tokenOfOwnerByIndex(account, i);
        const filter = await contract.tokenURI(id.toString());
        const res = await axios.get(filter, {
          cancelToken: cancelTokenSource && cancelTokenSource.token,
        });
        if (res.status === 200) {
          setUserNFTList((prev) => [
            ...prev,
            { id: id.toString(), nfdData: res.data },
          ]);
        }
      }
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    const cancelTokenSource = axios.CancelToken.source();

    if (balanceOfValue > 0) {
      userNFTListHadler(balanceOfValue, cancelTokenSource);
    }
    return () => {
      cancelTokenSource.cancel();
    };
  }, [balanceOfValue, account]); //eslint-disable-line

  // const allNFTListHandler = async (txaCount) => {
  //   //eslint-disable-line no-unused-vars
  //   setIsLoadingAllNFT(true);
  //   const contract = await getWeb3ContractObject(abi, token);
  //   try {
  //     for (let i = 1; i <= parseInt(txaCount); i++) {
  //       const tokenURI = await contract.methods.tokenURI(i.toString()).call();
  //       const res = await axios.get(tokenURI);
  //       if (res.status === 200) {
  //         setallNftList((prev) => [
  //           ...prev,
  //           { id: i.toString(), nfdData: res.data },
  //         ]);
  //       }
  //     }
  //     setIsLoadingAllNFT(false);
  //   } catch (error) {
  //     setIsLoadingAllNFT(false);

  //     console.log("ERROR", error);
  //   }
  // };
  // useEffect(() => {
  //   if (totalSupply > 0) {
  //     allNFTListHandler(totalSupply);
  //   }
  // }, [totalSupply]); //eslint-disable-line
  const walletImage = async () => {
    setDataloader(true);
    const contract = Webobj(abi, token);
    const nftContract = Webobj(NFTRegistryABI, nftRegister);
    console.log("nftContract1", nftContract);
    let arr = [];
    console.log("cryptovalue", cryptovalue);
    try {
      for (let i = 0; i < cryptovalue; i++) {
        console.log("hello", i);
        const tx = await contract.methods
          .tokenOfOwnerByIndex(account, i)
          .call();
        const nftTx = await nftContract.methods.getIPFSHashOfNFTId(tx).call();
        const name = await contract.methods.tokenNameByIndex(i).call();
        const image = ("https://ipfs.io/ipfs/" + nftTx).toString();
        console.log("image", image);
        arr.push({ nftTrax: tx, imageurl: image });
        console.log("ddd+++", arr);
        setwalletDetails((prev) => [
          ...prev,
          { nftTrax: tx, imageurl: image, name: name },
        ]);
        console.log("ddd+++", arr);
      }
      console.log("data", arr);
      setDataloader(false);
    } catch (e) {
      console.log(e);
    }
    setDataloader(false);
    console.log(arr);
    setData(arr);
    setavailable(true);
  };
  React.useEffect(() => {
    if (account !== "" && typeof account !== "undefined") {
      console.log(account);

      Walletdata();
      Cryptoowned();
      console.log("jio2");
    }
  }, [account]);
  React.useEffect(() => {
    if (account !== "" && typeof account !== "undefined" && cryptovalue !== 0) {
      console.log(account);
      // Accumulated();
    }
    if (cryptovalue !== 0) {
      walletImage();
    }
  }, [account, cryptovalue]);

  const getContractDetails = async () => {
    try {
      const web3 = await getWeb3Obj();
      const contractObj = await getWeb3ContractObject(abi, token);
      const adminAccount = await contractObj.methods.owner().call();
      setAdminWalletAddress(adminAccount);
      const hasFinalSaleStarted = await contractObj.methods
        .hasFinalSaleStarted()
        .call();
      setHasFinalSaleStarted(hasFinalSaleStarted);
      console.log("contractObj", contractObj);
      const publicSaleStartTimestamp = await contractObj.methods
        .publicSaleStartTimestamp()
        .call();
      setPublicSaleStartTimestamp(publicSaleStartTimestamp);

      // const MAX_MINT = await contractObj.methods.MAX_MINT().call();
      // setMAX_MINT(Number(MAX_MINT));

      if (hasFinalSaleStarted) {
        const NFT_PRICE = await contractObj.methods.NFT_PRICE().call();
        const getNFTPrice = await web3.utils.fromWei(NFT_PRICE.toString());
        setNftPrice(getNFTPrice);
        const MAX_NFT_WALLET = await contractObj.methods
          .MAX_NFT_WALLET()
          .call();
        setMAX_NFT_WALLET(Number(MAX_NFT_WALLET));
      } else {
        const RESERVE_PRICE = await contractObj.methods.NFT_PRICE().call();
        const getRESERVE_PRICE = await web3.utils.fromWei(
          RESERVE_PRICE.toString()
        );
        setNftPrice(getRESERVE_PRICE);
        const MAX_NFT_WALLET = await contractObj.methods
          .MAX_NFT_WALLET_PRESALE()
          .call();
        setMAX_NFT_WALLET(Number(MAX_NFT_WALLET));
        // const reservedClaimed = await contractObj.methods
        //   .reservedClaimed()
        //   .call();
        // const RESERVED_NFT = await contractObj.methods.RESERVED_NFT().call();
        // setReservedClaimed(10);
        // setRESERVED_NFT(20);
      }
    } catch (error) {
      console.log("ERROR", error);
    }
  };

  useEffect(() => {
    if (account) {
      getBalanceOfFun();
    }
  }, [account]); //eslint-disable-line

  async function getBalanceOfFun() {
    setBalanceOfValue(await getBalanceOf(abi, token, account));
  }

  const getCurrentMintingDetails = async () => {
    const contractObj = await getWeb3ContractObject(abi, token);
    if (account) {
      getBalanceOfFun();
    }
    const MAX_NFT_SUPPLY = await contractObj.methods.MAX_NFT_SUPPLY().call();
    setMAX_NFT_SUPPLY(Number(MAX_NFT_SUPPLY));
    const totalSupply = await contractObj.methods.totalSupply().call();
    setTotalSupply(Number(totalSupply));
    let per = (parseFloat(totalSupply) / parseFloat(MAX_NFT_SUPPLY)) * 100;
    setMintPercentage(per);
  };

  useEffect(() => {
    getContractDetails();
    getCurrentMintingDetails();
  }, []); //eslint-disable-line

  useEffect(() => {
    if (account && chainId) {
      if (chainId !== ACTIVE_NETWORK) {
        if (window.ethereum) {
          swichNetworkHandler();
        }
      }
    }
  }, [chainId, account]); //eslint-disable-line

  useEffect(() => {
    const userAddress = window.sessionStorage.getItem("userAddress");
    if (userAddress) {
      data.connectWallet();
    }
  }, []); //eslint-disable-line

  useEffect(() => {
    data.updateUser(account);
  }, [account]); //eslint-disable-line

  useEffect(() => {
    if (!account) {
      deactivate();
    }
  }, [account]); //eslint-disable-line

  return (
    <UserContext.Provider value={data}>{props.children}</UserContext.Provider>
  );
}

// import React, { createContext, useEffect } from "react";
// import { injected } from "src/connectors";
// import { useWeb3React } from "@web3-react/core";

// export const UserContext = createContext();

// const setSession = (userAddress) => {
//   if (userAddress) {
//     sessionStorage.setItem("userAddress", userAddress);
//   } else {
//     sessionStorage.removeItem("userAddress");
//   }
// };

// export default function AuthProvider(props) {
//   const { activate, account } = useWeb3React();

//   let data = {
//     updateUser: (account) => {
//       setSession(account);
//     },
//     connectWallet: () => {
//       activate(injected, undefined, true).catch((error) => {
//         if (error) {
//           activate(injected);
//         }
//       });
//     },
//   };

//   useEffect(() => {
//     const userAddress = window.sessionStorage.getItem("userAddress");
//     if (userAddress) {
//       data.connectWallet();
//     }
//   }, []); //eslint-disable-line

//   useEffect(() => {
//     data.updateUser(account);
//   }, [account]); //eslint-disable-line

//   return (
//     <UserContext.Provider value={data}>{props.children}</UserContext.Provider>
//   );
// }
