export const NetworkContextName = "Ethereum Testnet";
export const ACTIVE_NETWORK = 42;

export const RPC_URL =
  "https://kovan.infura.io/v3/9aa3d95b3bc440fa88ea12eaa4456161";
// "https://mainnet.infura.io/v3/9aa3d95b3bc440fa88ea12eaa4456161";
export const token = "0x41e3a659443ed7ac8bb3a853a4d30167bbc37449";

export const NetworkDetails = [
  {
    chainId: "42",
    chainName: "Ethereum Mainnet",
    nativeCurrency: {
      name: "Ethereum TestNet",
      symbol: "ETH",
      decimals: 18,
    },
    rpcUrls: ["https://kovan.infura.io/v3/9aa3d95b3bc440fa88ea12eaa4456161"],
    blockExplorerUrls: ["https://kovan.etherscan.io"],
  },
];
export const nftRegister = "0x39d6eBCDB4DF4ff410eFfe23292ba70F3A603566";
// export const NFTRegistry = "0x0a9AF8281604782bb7195e947786A2c769957f10";

export const NFTMetadataStoreToken =
  "0x2C39103a3B7A86823444B152A559dF5007fd4599";
export const TestERC = "0xba492781fca6537d2fb7321224b29fc5654bc29f";

//New ...

// NFT: 0x41e3a659443ed7ac8bb3a853a4d30167bbc37449;
// NFTMetadataStore: 0x2c39103a3b7a86823444b152a559df5007fd4599;
// NFTRegistry: 0x39d6ebcdb4df4ff410effe23292ba70f3a603566;
// TestERC: 0xba492781fca6537d2fb7321224b29fc5654bc29f;
